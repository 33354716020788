import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { darken } from 'polished'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import { Layout, Wrapper, SectionTitle, Content } from '../components'
import Logo from '../assets/img/logo.svg'
import Ripple from '../assets/img/ripple.svg'

const Hero = styled.div`
  grid-column: 2;
  position: relative;
  z-index: 3;
  padding: 1rem 1rem 1rem;
  background: ${props => props.theme.colors.primary};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0.5rem;
  }

  .logo {
    text-align: center;

    svg {
      @media (max-width: ${props => props.theme.breakpoints.phone}) {
        width: 330px;
        margin: auto;
      }

      text {
        font-family: 'dehangsterregular', cursive;
      }
    }
  }
`

const Book = styled.div`
  .access {
    color: ${props => props.theme.colors.grey};
    font-size: 1.15rem;
    line-height: 1.25;
    text-align: center;
    margin-bottom: 2rem;
  }

  .signup {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      flex-direction: column;
      margin-bottom: 2rem;
      padding: 0 2rem;

      div {
        width: 100%;
      }
      button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }

    div {
      margin-right: 0.5rem;
    }
    button {
      margin-top: 0.25rem;
    }
    .input div input {
      color: ${props => props.theme.colors.grey};
      border-color: ${props => props.theme.colors.grey};
    }
    fieldset {
      border-color: ${props => props.theme.colors.grey};

      &:hover, &:active {
        border-color: ${props => props.theme.colors.grey} !important;
      }
    }
    label {
      color: ${props => props.theme.colors.grey};
    }
    .MuiOutlinedInput-root-23.MuiOutlinedInput-focused-24 .MuiOutlinedInput-notchedOutline-30, .MuiOutlinedInput-root-23:hover:not(.MuiOutlinedInput-disabled-25):not(.MuiOutlinedInput-focused-24):not(.MuiOutlinedInput-error-28) .MuiOutlinedInput-notchedOutline-30 {
      border-color: ${props => props.theme.colors.grey};

      &:hover {
        border-color: ${props => props.theme.colors.grey};
      }
    }
  }
`

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

const Response = styled.div`
  height: 200px;
  max-width: 700px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.grey};

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    height: 100px;
    margin: 0 auto 1rem auto;
  }

  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    color: ${props => props.theme.colors.grey.default};
    text-align: center;
    background: ${props => props.theme.colors.grey};
    border-radius: 16px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

const GfcHowToSayNo = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriber, setSubscriber] = useState(false);
  const [failure, setFailure] = useState(false);

  const mcFreeEbook = (ev) => {
    setSubscribe(!subscribe)
    setLoading(true)

    let postObj = {
      email_address: email,
      status: "subscribed",
      tags: ["Main"],
      merge_fields: {
        "FNAME": firstName
      }
    };

    // fetch('http://localhost:9000/mailchimp', {
    fetch('https://selfdevlab.com/.netlify/functions/mailchimp', {
      method: 'POST',
      body: JSON.stringify(postObj),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.status === 400 && response.title === "Member Exists") {
          setLoading(false)
          setSubscriber(!subscriber)
          console.log('Success:', JSON.stringify(response))
        } else {
          setLoading(false)
          setSuccess(!success)
        }
      })
      .catch(error => {
        setLoading(false)
        setFailure(!failure)
        console.error('Error:', error)
      });
  }

  return (
    <Layout>
      <Hero>
        <div className="logo">
          <Logo width="400" height="68" />
        </div>
      </Hero>
      <Wrapper>
        <Content>
          <Fade in={true} style={{ transitionDelay: '500ms' }}>
            <Book>
              <p className="access">Welcome, GirlFriend Circle reader! Here's your free ebook, How to Say "No" Without Being a Selfish Jerk.</p>
              <Response>
                {subscribe && <div>
                  <form className="signup">
                    <TextField
                      className="input"
                      label="First Name"
                      type="test"
                      name="firstName"
                      margin="dense"
                      variant="outlined"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                    <TextField
                      className="input"
                      label="Email"
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="dense"
                      variant="outlined"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <ColorButton variant="contained" size="large" onClick={mcFreeEbook}>
                      Get It Now!
                    </ColorButton>
                  </form>
                </div> }
                {loading && <Ripple width="100" height="100" />}
                {success && <div className="message"><p>Congratulations! Click <a href="/downloads/How_to_say_no_wo_feeling_like_jerk.pdf" filename="How_to_Say_No_Withoug_Feeling_Like_Jerk" download>this link</a> for the ebook.</p></div>}
                {subscriber && <div className="message"><p>Congratulations! You already subscribed some time in the past. If you need the download link again, <a href="/downloads/How_to_say_no_wo_feeling_like_jerk.pdf" filename="How_to_Say_No_Withoug_Feeling_Like_Jerk" download>here it is!</a></p></div>}
                {failure && <div className="message"><p>Something didn't work. Please refresh the page and try again.</p></div>}
              </Response>
            </Book>
          </Fade>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default GfcHowToSayNo

